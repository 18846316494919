import './icon-list.scss';
import Swiper from '../../vendor/swiper/swiper';

class IconList {
    constructor ($element) {
        this.$trusted = $element;
        this.$slider = this.$trusted.querySelectorAll('[data-icon-list-slider="slider"]');
        this.$slides = this.$trusted.querySelectorAll('[data-icon-list-slider="slide"]');
        this.centered = true;

        this.slider = null;
    }

    initialize () {
        this.checkSize();
    }

    checkSize () {
        if (this.$slides.length > 3 || (window.innerWidth < 500 && this.$slides.length === 2) || (this.$slides.length === 3 && window.innerWidth < 840)) {
            if (this.slider === null) {
                this.initSlider();
            }
        }

        window.resizeHandler.customFunctions.push(() => {
            if (this.$slides.length > 3 || (window.innerWidth < 500 && this.$slides.length === 2) || (this.$slides.length === 3 && window.innerWidth < 840)) {
                if (this.slider === null) {
                    this.initSlider();
                }
            } else {
                this.destroySlider();
            }
        });
    }

    initSlider () {
        this.slider = new Swiper(this.$slider, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            breakpointsInverse: true,
            pagination: {
                el: '.icon-list-pagination',
                clickable: true
            },
            breakpoints: {
                500: {
                    slidesPerView: 2,
                    spaceBetween: 55
                },
                840: {
                    slidesPerView: 3,
                    spaceBetween: 75
                }
            }
        });
    }

    destroySlider () {
        if (this.slider !== null) {
            this.slider.destroy(true, true);

            for (let i = 0; i < this.$slides.length; i++) {
                this.$slides.style = '';
            }

            this.slider = null;
        }
    }
}

export { IconList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const $iconList = $context.querySelector('[data-icon-list-slider="root"]');

    if ($iconList) {
        const $newIconList = new IconList($iconList);
        $newIconList.initialize();
    }
});
